const config = function () {
  return {
    baseURL: 'https://tasks.tgapp.live/',
    headers: {
      Accept: 'application/json',
    },
  };
};

export default config;
