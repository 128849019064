import API from '@/api';
export default {
    getCategoryList: (params = {}) => {
        return API.get(`/categories`, {
            params
        });
    },
    createCategory: (params = {}) => {
        return API.post(`/categories`, params);
    },
    deleteCategory: (id = 0) => {
        return API.delete(`/categories/${id}`);
    },
};
